<template>
  <v-container fluid id="threeJS" class="threeJsCard">
    <v-btn
      v-if="showButton"
      id="startVideoButton"
      elevation="0"
      large
      @click="startVideo"
      >Iniciar</v-btn
    >
    <canvas height="100vh" width="100vw" ref="canvas"></canvas>
    <!--
      <video id="merzVideo">
        <source src="../../public/video/merz.mp4" />
      </video>
    <v-btn
      style="position: absolute; z-index: 4"
      elevation="0"
      large
      @click="pauseVideo"
      >Pausar</v-btn
    >
            -->
  </v-container>
</template>
<script>
import * as THREE from "three";
import ChromaKeyMaterial from "@/components/js/ChromaKeyMaterial";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
//import { GUI } from "three/examples/jsm/libs/lil-gui.module.min.js";

export default {
  name: "THREEComponent",
  data: () => ({
    renderer: null,
    scene: null,
    camera: null,
    ambientLight: null,
    light: null,
    orbitControls: null, // Função de controle
    orbitCam: new THREE.Vector3(), // Vetor para capturar posição do mapa/camera
    minPan: null, // Limitando a movimentação do mapa na tela
    maxPan: null, // Limitando a movimentação do mapa na tela
    showButton: true,
    gui: null,
    params: {
      similarity: 0,
      smoothness: 0,
      spill: 0,
    },
    paused: false,
    plane: null,
  }),
  mounted() {
    this.initProject();
  },
  methods: {
    async initProject() {
      await this.createRenderer();
      await this.createScene();
      await this.createCamera();
      await this.createlight();
      //await this.addVideoComponent();
      await this.addObjetcScene();
      this.animate();
    },
    async createRenderer() {
      return new Promise((resolve) => {
        THREE.Cache.enabled = true;
        this.renderer = new THREE.WebGLRenderer({
          alpha: true,
          antialias: true,
          preserveDrawingBuffer: true,
          canvas: document.querySelector("canvas"),
        });
        this.renderer.setPixelRatio(window.devicePixelRatio); // Definindo o aspecto da tela
        this.renderer.setSize(window.innerWidth, window.innerHeight);
        resolve();
      });
    },
    async createScene() {
      return new Promise((resolve) => {
        this.scene = new THREE.Scene();
        this.scene.name = "Video Scene"; // Alterando o nome da cena
        //this.scene.background = new THREE.Color(0x0000ff);
        this.scene.userData.element = document.querySelector("canvas"); // Vinculando o canvas (domElement) na cena criada
        //this.gui = new GUI();
        resolve();
      });
    },
    async createCamera() {
      return new Promise((resolve) => {
        this.camera = new THREE.PerspectiveCamera(
          75,
          window.innerWidth / window.innerHeight,
          0.1,
          1000
        );
        this.camera.position.z = 3;

        // Configurações do controle da câmera na tela
        this.orbitControls = new OrbitControls(
          this.camera,
          this.scene.userData.element
        ); // Criando um novo tipo de controle orbital (envolta da cena)

        this.orbitControls.screenSpacePanning = true; // Habilita a movimentação com efeito de smooth
        this.orbitControls.minPolarAngle = (60 * Math.PI) / 180.0; // Distancima minima de rotação eixo X (não ve parte debaixo do mapa)
        this.orbitControls.maxPolarAngle = (120 * Math.PI) / 180.0; // Distancima máxima de rotação eixo X (não ve parte debaixo do mapa)

        this.orbitControls.minAzimuthAngle = (-20 * Math.PI) / 180.0; // Limitador do eixo Y
        this.orbitControls.maxAzimuthAngle = (20 * Math.PI) / 180.0; // Limitador do eixo Y

        this.orbitControls.mouseButtons = {
          LEFT: THREE.MOUSE.PAN,
          MIDDLE: THREE.MOUSE.DOLLY,
          RIGHT: THREE.MOUSE.ROTATE,
        }; // Definindo o tipo de clique no mapa, botão esquerdo move mapa, direito gira mapa
        this.orbitControls.touches.ONE = THREE.TOUCH.PAN; // Definindo o tipo do toque e sua função 1 dedo movimenta
        this.orbitControls.touches.TWO = THREE.TOUCH.DOLLY_ROTATE; // Definindo o tipo do toque e sua função 2 dedos gira

        // Configurações para limitar a movimentação do mapa na tela
        this.orbitControls.addEventListener("change", () => {
          this.orbitCam.copy(this.orbitControls.target);
          this.orbitControls.target.clamp(this.minPan, this.maxPan);
          this.orbitCam.sub(this.orbitControls.target);
          this.camera.position.sub(this.orbitCam);
        });

        this.scene.userData.controls = this.orbitControls; // Adicionando o controle da cena
        resolve();
      });
    },
    async createlight() {
      return new Promise((resolve) => {
        this.ambientLight = new THREE.AmbientLight(0xffffff, 1.5); // Adicionando uma luz ambiente
        this.light = new THREE.DirectionalLight(0xffffff, 1.5); // Adicionando uma luz direcional para criar sombras
        // Configurações de luzes
        this.light.position.set(0, 40, 0); // Definindo posição da luz
        // Adicionando ferramentas na cena
        this.scene.add(this.ambientLight); // Adicionando luz ambiente
        this.scene.add(this.light); // Adicionando luz direcionando
        resolve();
      });
    },
    async addObjetcScene() {
      return new Promise((resolve) => {
        const chromaMaterial = new ChromaKeyMaterial(
          this.$route.params.locale === "es"
            ? "video/merz_es.mp4"
            : "video/merz_br.mp4",
          0x00ff00,
          1080,
          1920,
          this.$route.params.locale === "pt" || !this.$route.params.locale
            ? 0.3
            : 0.27,
          this.$route.params.locale === "pt" || !this.$route.params.locale
            ? 0.2
            : 0.15,
          this.$route.params.locale === "pt" || !this.$route.params.locale
            ? 0.35
            : 0.35
        );

        /*
        const chromaKey = this.gui.addFolder("Chroma Key");
        chromaKey
          .add(chromaMaterial.uniforms.similarity, "value", 0, 1, 0.001)
          .name("Similarity");
        chromaKey
          .add(chromaMaterial.uniforms.smoothness, "value", 0, 1, 0.001)
          .name("Smoothness");
        chromaKey
          .add(chromaMaterial.uniforms.spill, "value", 0, 1, 0.001)
          .name("Spill");
        chromaKey.open();
        */
        const geometry = new THREE.PlaneGeometry(9, 16);
        geometry.scale(0.5, 0.5, 0.5);
        this.plane = new THREE.Mesh(geometry, chromaMaterial);
        this.plane.visible = false;
        this.scene.add(this.plane);

        const videoBox = new THREE.Box3().setFromObject(this.plane); // Crio uma caixa virtual com o mapa atual
        const videoSize = videoBox.getSize(new THREE.Vector3()); // Pego o tamanho do mapa

        this.orbitControls.minDistance = videoSize.y / 1.5; // Maximo de zoom-in no mapa
        this.orbitControls.maxDistance = videoSize.x * 1.5; // Maximo de zoom-out no mapa

        this.minPan = new THREE.Vector3(
          -videoSize.x / 2,
          -videoSize.y / 2,
          -videoSize.z / 2
        );
        this.maxPan = new THREE.Vector3(
          videoSize.x / 2,
          videoSize.y / 2,
          videoSize.z / 2
        );
        videoSize;

        this.animate();
        resolve();
      });
    },
    render() {
      this.orbitControls.update(); // Atualizo o controle da câmera
      this.camera.updateMatrixWorld(); // Atualizo a posição da câmera
      this.renderer.render(this.scene, this.camera);
    },
    animate() {
      requestAnimationFrame(this.animate);
      this.render();
    },
    startVideo() {
      this.plane.visible = true;
      const video = document.getElementById("merzVideo");
      video.play();
      this.showButton = false;
    },
    /*
    pauseVideo() {
      const video = document.getElementById("merzVideo");
      if (!this.paused) {
        this.paused = true;
        video.pause();
      } else {
        this.paused = false;
        video.play();
      }
    },
    */
  },
};
</script>
<style scoped>
canvas {
  position: absolute;
  z-index: 1;
}
#merzVideo {
  height: 100vh;
  width: 100vw;
  display: none;
}
.threeJsCard {
  z-index: 2;
  background-color: transparent !important;
  padding: 0px;
  height: 100%;
}
#startVideoButton {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 4;
  color: #285a77 !important;
  background-color: #c3eafc !important;
  text-transform: capitalize;
  border: #285a77 double;
  border-radius: 8px;
}
</style>
