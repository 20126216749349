<template>
  <v-container fill-height fluid class="pa-0">
    <video
      id="video"
      playsinline
      webkit-playsinline
      autoplay
      loop
      muted
    ></video>
    <THREEjs />
  </v-container>
</template>

<script>
import THREEjs from "@/components/THREEJS";
export default {
  name: "HomeView",
  components: {
    THREEjs,
  },
  mounted() {
    this.startCamera();
  },
  methods: {
    startCamera() {
      if (
        "mediaDevices" in navigator &&
        "getUserMedia" in navigator.mediaDevices
      ) {
        const constraints = {
          audio: false,
          video: {
            facingMode: {
              exact: "environment",
            },
          },
        };
        navigator.mediaDevices
          .getUserMedia(constraints)
          .then((mediaStream) => {
            const video = document.getElementById("video");
            video.setAttribute("autoplay", "");
            video.setAttribute("muted", "");
            video.setAttribute("playsinline", "");
            video.srcObject = mediaStream;
            video.onloadedmetadata = () => {
              video.play();
            };
          })
          .catch((err) => {
            // always check for errors at the end.
            console.error(`${err.name}: ${err.message}`);
          });
      } else {
        console.error("MediaDevices interface not available.");
      }
    },
  },
};
</script>
<style scoped>
#video {
  position: absolute;
  top: 0%;
  left: 0%;
  object-fit: cover;
  object-position: bottom;
  width: 100vw;
  height: 101vh !important;
  z-index: 0;
}
</style>
